import React, { useState } from 'react';
import './methodology.css';
import threefold from '../../images/Threefold.png';
import startingImage from '../../images/man_in_hills.png'; 
import HeadingPage from './headingpage.js';
import Midsection from './midsection';

const Methodology = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <HeadingPage 
        title="Methodology" 
        description="Threefold Scientific Approach" 
        image={startingImage}
      />
      
      <div className='cards'>
        <div className='cards__container'>
          <div className='cards__wrapper'>

            {/* Image and Text Section (Side-by-Side Layout) */}
            <div className='cards__items'>
              <div className='cards__item'>

                {/* Image on the left */}
                <div className='cards__item__image'>
                  <img src={threefold} alt="Threefold Scientific Approach" className="methodology" />
                </div>

                {/* Text on the right */}
                <div className='cards__item__info'>
                  <div className='h1'>Overview</div>
                  <p className='paragraph1'>
                    <strong>Objective:</strong> The BeyondSDG project adopts a threefold scientific approach, integrating quantitative analysis, qualitative analysis, and knowledge co-creation to facilitate long-term sustainability.
                  </p>

                  <p className='paragraph1'>
                    {isExpanded ? (
                      <>
                        <div className='h1'>1. Quantitative Analysis</div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> Understand SDG interactions and effects of (under)achieving SDGs through empirical and modeled data.
                        </p>
                        <p className='paragraph1'>
                          <strong>Methods:</strong>
                          <ul className='list-disc'>
                            <li>Apply correlation analysis as a quantitative approach.</li>
                            <li>Identify causal relations beyond statistical associations.</li>
                            <li>Utilize data from multiple sources, including the unified SDG database.</li>
                            <li>Incorporate outcomes and projections from integrated assessment models (IAMs).</li>
                          </ul>
                        </p>

                        <div className='h1'>2. Qualitative Analysis</div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> Complement quantitative analyses by understanding underlying mechanisms or processes.
                        </p>
                        <p className='paragraph1'>
                          <strong>Methods:</strong>
                          <ul className='list-disc'>
                            <li>Use alternative methods such as meta-analyses, expert elicitations, and qualitative literature analysis.</li>
                            <li>Systematically analyze literature to synthesize evidence on SDG interactions' mechanisms and effects.</li>
                            <li>Address limitations of statistical noises and limited literature through expert elicitations.</li>
                            <li>Apply mixed-method approaches by integrating quantitative methods with the generated qualitative data.</li>
                          </ul>
                        </p>

                        <div className='h1'>3. Knowledge Co-Creation</div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> Facilitate transformative changes by collaboratively generating knowledge with stakeholders.
                        </p>
                        <p className='paragraph1'>
                          <strong>Methods:</strong>
                          <ul className='list-disc'>
                            <li>Engage various stakeholders, including the public sector, private sector, academia, NGOs, and the general public.</li>
                            <li>Support SDG localization by providing context- and location-specific insights.</li>
                            <li>Ensure diversity, inclusion, and gender dimensions in stakeholder engagements.</li>
                            <li>Employ methods such as surveys, workshops, focus groups, webinars, and interviews for effective knowledge co-creation.</li>
                          </ul>
                        </p>
                      </>
                    ) : (
                      <>
                        The BeyondSDG project uses a combined methodology to prioritize SDGs, understand SDG interactions, and engage stakeholders. 
                        <p className='paragraph1'><strong>1. Quantitative Analysis</strong></p>
                        <p className='paragraph1'><strong>2. Qualitative Analysis</strong></p>
                        <p className='paragraph1'><strong>3. Knowledge Co-Creation</strong></p>
                      </>
                    )}
                  </p>

                  <button className='read-more-btn' onClick={toggleReadMore}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      
      <Midsection />
    </div>
  );
}

export default Methodology;
