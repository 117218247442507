import React from 'react';
import './event.css';
import startingImage from '../../images/Forest.jpg'; 
import HeadingPage from './headingpage.js';
import Midsection from './midsection';

import image1 from '../../images/Highlight_3.jpg';
import image2 from '../../images/event_2.jpg';
import image3 from '../../images/university-of-groningen.png';
import image4 from '../../images/event_3.jpg';
import image5 from '../../images/event_4.png';
import image7 from '../../images/event_7.png';
import image6 from '../../images/event_6.png';

const eventsData = [
  {
    id: 1,
    title: 'Future of Sustainable Development: Bridging SDG Interactions, Modeling, Tools and Policy',
    date: '2024-04-22', 
    hosted: 'Rudolf Agricola School for Sustainable Development, University of Groningen',
    role: 'Co-hosted the event',
    logo: image1, 
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdqV8QLeGSUileSOUkXYCKAcuW90OUnfu9WxpWcG-h8WADgkA/viewform'
  },
  {
    id: 2,
    title: 'Annual international Conference of ISDR',
    date: '2024-04-10',
    hosted: 'Mid-West University and Nepal Open University',
    role: 'Organizing committee and co-chair of a session',
    logo: image2,
    url: 'https://2024.isdrsconferences.org/'
  },
  {
    id: 3,
    title: 'Intergovernmental Panel on Climate Change: Cities Dialogue and Beyond',
    date: '2024-08-06',
    hosted: 'Rudolf Agricola School for Sustainable Development and Wubbo Ockels School for Energy and Climate',
    role: 'Organizing committee and co-chair of a session',
    logo: image3,
    url: 'https://www.rug.nl/wubbo-ockels-school/calendar/2024/ipcc-cities-dialogue-and-beyond?lang=en'
  },
  {
    id: 4,
    title: 'International Conference on Sustainable Goals 2.0 (ICSG 2.0)',
    date: '2025-02-01',
    hosted: 'IIM Bodh Gaya',
    role: 'Organizing committee',
    logo: image4,
    url: 'https://iimbg.ac.in/icsg2/'
  },
  {
    id: 5,
    title: '14th International Symposium on Digital Earth',
    date: '2025-04-21',
    hosted: 'CNISDE, CBAS, School of Geographical Science and Changang Yuelai International Convention Center',
    role: 'Co-chair two sessions',
    logo: image5,
    url: 'https://isde2025.event.evtr.cn/site/pages/index/index?pageId=e7498bf3-b5c9-43a6-b002-f976056cd6b1'
  },
  {
    id: 6,
    title: '31st Annual Conference of ISDRS, 8-12 July, 2025',
    date: '2025-07-08',
    hosted: 'Corvinus University of Budapest',
    role: 'Organizing committee',
    logo: image6,
    url: 'https://2025.isdrsconferences.org/pricing-and-information-2/'
  },
  {
    id: 7,
    title: 'IGSD 2025 Geography and Sustainable Development: From a Transdisciplinary Perspective',
    date: '2025-10-17',
    hosted: 'Beijing Normal University, Shaanxi Normal University and The Geographical Society of China',
    role: 'Scientific committee',
    logo: image7,
    url: 'https://2025.geosus.org/html/Register/'
  },
];

const currentDate = new Date();

// Filter events
const upcomingEvents = eventsData
  .filter(event => new Date(event.date) >= currentDate)
  .sort((a, b) => new Date(b.date) - new Date(a.date));

const pastEvents = eventsData
  .filter(event => new Date(event.date) < currentDate)
  .sort((a, b) => new Date(b.date) - new Date(a.date));

const EventCard = ({ event }) => {
  return (
    <div className="EventsContainer">
      <img src={event.logo} alt={event.title} />
      <div className="event-details">
        <h3>{event.title}</h3>
        <p><strong>Date:</strong> {event.date}</p>
        <p><strong>Hosted By:</strong> {event.hosted}</p>
        <p><strong>Contribution:</strong> {event.role}</p>
        <a href={event.url} target="_blank" rel="noopener noreferrer">
          <p className="read-more-btn">READ MORE</p>
        </a>
      </div>
    </div>
  );
};

const Events = () => {
  return (
    <div>
      <HeadingPage 
        title="Events" 
        description="" 
        image={startingImage}
      />
      <div className='main-card'>
        <h2>UPCOMING EVENTS</h2>
        <div className='highlight-card'>
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map(event => <EventCard key={event.id} event={event} />)
          ) : (
            <p>No upcoming events.</p>
          )}
        </div>

        <h2>PAST EVENTS</h2>
        <div className='highlight-card'>
          {pastEvents.length > 0 ? (
            pastEvents.map(event => <EventCard key={event.id} event={event} />)
          ) : (
            <p>No past events.</p>
          )}
        </div>
        
      
        <Midsection />
      </div>
  
    </div>
  );
};

export default Events;
