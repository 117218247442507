import React from 'react';
import './teams.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import team1Image from '../../images/team1.jpeg'; 
import team2Image from '../../images/team2.jpg'; 
import team4Image from '../../images/team4.jpg'; 
import allteam from '../../images/IMG_5413.jpg'; 
import team5Image from '../../images/Teun.jpg'; 
import team6Image from '../../images/Utkash.jpg';
import team7Image from '../../images/Jing_photo.jpg';
import HeadingPage from './headingpage.js';
import Midsection from './midsection';


function Team() {
  
	return (
		<div>
	<HeadingPage 
  title="Meet The Team" 
  description=""
  staticImage={allteam} // Static image for this page
/>
		<div id="team">
			<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
<div class="container-team">
<div class="section ourTeam">
	<header class="text-center">
		
	</header>
	<div class="row">
		<div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team1Image}  alt="#" class="img-responsive"/>
					<div class="info"> 
						<h3 class="name">Dr. Prajal Pradhan</h3>
						<h4 class="position">Assistant Professor</h4>
					</div>
				</div>
				<div class="more">
					<p>Prajal Pradhan is an Assistant Professor at the University of Groningen and a visiting scientist at the PIK. He studied agricultural engineering and environmental management</p>
					<div class="socials">

						<a href="https://www.linkedin.com/in/prajal-pradhan-7a51b26/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team2Image} alt="#" class="img-responsive"/>
					<div class="info">
						<h3 class="name">Anne Warchold</h3>
						<h4 class="position">PhD student</h4>
					</div>
				</div>
				<div class="more">
					<p>Anne Warchold completed her master's degree in sustainability management at the Leipzig Universität. She has a bachelor's degree in environmental management and energy economics.</p>
					<div class="socials">
					
						<a href="https://www.linkedin.com/in/anne-warchold/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
				<div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team5Image}  alt="#" class="team-image img-responsive"/>
					<div class="info">
						<h3 class="name"> Teun Kluck</h3>
						<h4 class="position">PhD student</h4>
					</div>
				</div>
				<div class="more">
					<p>Teun Kluck is an PhD student at the University of Groningen. He completed his master's from University of Utrecht in Theoretical  Physics. He is currently working on how we can use models to get a holistic view of the SDGs and long-term environmental sustainability</p>
					<div class="socials">
					
						<a href="https://www.linkedin.com/in/teun-kluck-734328291/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team6Image} alt="#" class="img-responsive"/>
					<div class="info">
						<h3 class="name">Utkarsh Ashok Khot</h3>
						<h4 class="position">PhD Student</h4>
					</div>
				</div>
				<div class="more">
					<p>Utkarsh is a GIS professional with a masters degree in natural resource management and development. He is currently doing his PhD with University of Groningen.
</p>
					<div class="socials">
					
						<a href="https://www.linkedin.com/in/utkarsh-ashok-khot-497950111/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team7Image}alt="#" class="img-responsive"/>
					<div class="info">
						<h3 class="name">Jing Li</h3>
						<h4 class="position">PhD Student</h4>
					</div>
				</div>
				<div class="more">
					<p> Jing LI completed her Master’s degree in International Social and Public Policy at London School
of Economics in 2022. She did her Bachelor’s degree in Social Sciences from Waseda. Currently researching on the social foundation for long-term sustainability. </p>
					<div class="socials">
					
					
						<a href="https://www.linkedin.com/in/ainarasolajimenez/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
		{/* <div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team3Image}alt="#" width="270" height="270" class="img-responsive"/>
					<div class="info">
						<h3 class="name">Ainara Sola Jimenez</h3>
						<h4 class="position">Student Assistant</h4>
					</div>
				</div>
				<div class="more">
					<p> Ainara Sola Jimenez is currently pursuing a Master's in Energy and Environmental Sciences (EES), with a strong focus on Energy Systems and Modeling. </p>
					<div class="socials">
					
					
						<a href="https://www.linkedin.com/in/ainarasolajimenez/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div> */}
		<div class="col-xs-8 col-sm-4 col-md-4 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team4Image}alt="#"  class="img-responsive"/>
					<div class="info">
						<h3 class="name">Sitashma Rajbhandari</h3>
						<h4 class="position">Student Assistant</h4>
					</div>
				</div>
				<div class="more">
					<p>Sitashma Rajbhandari is currently a master's student at Otto-von-Guericke University Magdeburg, specializing in the field of data and knowledge engineering.</p>
					<div class="socials">
					
					<a href="https://www.linkedin.com/in/sitashma-rajbhandari/" title="#" class="linkedin"  target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin"></i></a>

					</div>
				</div>
			</div>
		</div>

		{/* <div class="col-xs-6 col-sm-3 col-md-3 i">
			<div class="c text-center">
				<div class="wrap">
					<img src={team4Image}alt="#" width="270" height="270" class="img-responsive"/>
					<div class="info">
						<h3 class="name">Sitashma Rajbhandari</h3>
						<h4 class="position">Student Assistant</h4>
					</div>
				</div>
				<div class="more">
					<p>Sitashma Rajbhandari is currently a master's student at Otto-von-Guericke University Magdeburg, specializing in the field of data and knowledge engineering.</p>
					<div class="socials">
					
						<a href="https://www.linkedin.com/in/sitashma-rajbhandari/" title="#" class="linkedin" target="_blank" rel="noopener noreferrer" ><i class="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
		 */}
	
		
	</div>
</div>
</div>
		</div>
		<Midsection />
		</div>
	);
}
export default Team;