import React, { useState } from 'react';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import './contact.css';



Modal.setAppElement('#root'); // This binds your modal to your app element (usually the root div in your index.html)

function ContactFormModal({ isOpen, onRequestClose }) {
    const [formData, setFormData] = useState({ name: '', email: '',subject:'', message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_82srpzl', 'template_mpnqzyj', e.target, 'ek6pYQ_v-5zXnkSJ1')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        onRequestClose(); // Close the modal
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Contact Form">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit} className='contact-form'>
                <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                <input type="subject" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
                <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required />
                <button type="submit">Send</button>
            </form>
        </Modal>
        
    );
}

export default ContactFormModal;
