import React from 'react';
import ReactDOM from 'react-dom/client';
import "@fontsource/league-spartan"; 
import "@fontsource/league-spartan/400.css"; 
import "@fontsource/league-spartan/700.css"; 


import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />
  
);


