import React, { useState } from 'react';
import './landingPage.css';
import aboutImage from '../../images/SDGWheel.png';
import aboutImage2 from '../../images/Image33.jpg';
import HeadingPage from './headingpage.js';
import Midsection from './midsection';

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
  <HeadingPage 
  title="About" 
  description="Discover more about what we do."
/>

      
      <div className='cards'>
        <div className='cards__container'>
          <div className='cards__wrapper'>

            {/* First Card Section */}
            <div className='cards__items'>
              <div className='cards__item'>
                <div className='cards__item__image'>
                  <img src={aboutImage} alt="Sustainable Development Goals" className="Image_landing" />
                </div>
                <div className='cards__item__info'>
                  <div className='h1'>BeyondSDG Overview</div>
                  <p className='paragraph1'>
                    BeyondSDG addresses the critical challenges in meeting the 2030 Agenda for Sustainable Development, comprising 17 Sustainable Development Goals (SDGs) with 169 targets. The 2030 Agenda aims to balance social, economic, and environmental sustainability. However, current trends indicate that countries, including the European Union, are not on track to meet these goals, and the COVID-19 pandemic has further hindered progress.
                  </p>
                  <p className='paragraph1'>
                    The rest of the 2020s is crucial to accelerating adequate actions for achieving the SDGs and adopting a post-2030 Sustainable Development Agenda, i.e., following up on SDGs for transformation towards long-term sustainability. BeyondSDG addresses this need in a timely manner by better understanding the critical targets to prioritize SDGs, the effects of (under)achieving SDGs on long-term sustainability, and the next-generation sustainability targets.
                  </p>
                </div>
              </div>
            </div>

            {/* Second Card Section */}
            <div className='cards__items'>
              <div className='cards__item'>
              <div className='cards__item__image'>
                  <img src={aboutImage2} alt="Sustainable Development Goals" className="Image_landing" />
                </div>
                <div className='cards__item__info'>
                  <div className='h1'>SDG Prioritization</div>
                  <p className='paragraph1'>
                    BeyondSDG emphasizes the importance of prioritizing SDGs, especially considering that a significant portion of the timeframe to achieve the 2030 Agenda has already passed. It recognizes the need for transformative changes rather than continuing business-as-usual trends.
                  </p>
                  <p className='paragraph1'>
                    {isExpanded ? (
                      <>
                        BeyondSDG proposes frameworks to prioritize SDGs by understanding their positive (synergies) and negative (trade-offs) interactions and leveraging them to maximize outcomes. It seeks to advance understanding of these interactions through qualitative (e.g., literature reviews, expert elicitation) and quantitative (e.g., data analysis) research methods.
                        BeyondSDG uses the vast amount of literature on sustainability to systematically understand the underlying mechanisms that enable or disable transformative changes. It also stresses the importance of understanding the long-term impacts of achieving or underachieving SDGs, especially in building social prosperity and foundations within planetary boundaries.
                        Looking beyond 2030, BeyondSDG recognizes the need for a post-2030 Development Agenda with more ambitious, science-informed, and specific targets. It advocates for a knowledge co-creation approach, involving stakeholders from various sectors, to ensure that the next-generation sustainability targets are accepted and implemented in the post-2030 political process.
                      </>
                    ) : (
                      <>The rest of the 2020s is crucial to accelerating adequate actions for achieving the SDGs and adopting a post-2030 Sustainable Development Agenda.</>
                    )}
                  </p>
                  <button className='read-more-btn' onClick={toggleReadMore}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Midsection />
    </div>
  );
};

export default About;
