import React from 'react';
import '../../../App.css';
import Introduction from '../Landing_page';
import Homepage_Title from './Homepage_Title';

import Midsection from '../midsection';

function Home() {
  return (
    <>
      <Homepage_Title />
      <Introduction />
     
      <Midsection />
      
    
   
  
    </>
  );
}

export default Home;