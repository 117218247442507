import React, { useEffect, useState } from 'react';
import './heading.css';
import Image1 from '../../images/IMG_0028.jpg';

import Image3 from '../../images/IMG_1903.jpg';
import Image6 from '../../images/IMG_0613.jpg';
import Image10 from '../../images/IMG_0772.jpg';
import Image13 from '../../images/image31.jpeg';


import Image14 from '../../images/image32.jpeg';

function HeadingPage({ title, description, staticImage = null }) {
  const images = [Image1, Image3, Image6, Image10, Image14,Image13];
  
  // Set the initial index to a random value between 0 and images.length - 1
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * images.length)
  );

  useEffect(() => {
    // If staticImage is provided, skip the slideshow effect
    if (staticImage) return;

    // Preload all images for a smooth transition
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    const heroContainer = document.querySelector('.hero-container');
    if (heroContainer) {
      heroContainer.classList.add('animation-active');
    }

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, [images, staticImage]);

  const backgroundStyle = {
    backgroundImage: `url(${staticImage || images[currentImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className='heading-container' style={backgroundStyle}>
      <div className="heading-content">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default HeadingPage;
