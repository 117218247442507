import React, { useState, useEffect } from 'react';
import companyImage from '../images/Logo_beyond.png';
import "@fontsource/league-spartan"; 
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(null); // Track open dropdown
  const [button, setButton] = useState(true);

  const handleClick = () => {
    setClick(!click);
    setDropdown(null); // Close dropdown when toggling mobile menu
  };

  const closeMobileMenu = () => {
    setClick(false);
    setDropdown(null); // Close dropdowns when menu is closed
  };

  const toggleDropdown = (menu) => {
    setDropdown(dropdown === menu ? null : menu); // Toggle dropdown, ensuring only one is open at a time
  };

  const showButton = () => {
    setButton(window.innerWidth > 960);
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
    return () => window.removeEventListener('resize', showButton);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.nav-item')) {
        setDropdown(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <img src={companyImage} alt="Company Logo" className='logo-image' />
        
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>

            {/* About Dropdown */}
            <li className='nav-item' onClick={() => toggleDropdown('about')}>
              <div className='nav-links'>
                About
              </div>
              {dropdown === 'about' && (
                <ul className="dropdown-menu show">
                  <li><Link to='/about' onClick={closeMobileMenu}>Project</Link></li>
                  <li><Link to='/team' onClick={closeMobileMenu}>Team</Link></li>
                </ul>
              )}
            </li>

            {/* Research Dropdown */}
            <li className='nav-item' onClick={() => toggleDropdown('research')}>
              <div className='nav-links'>
                Research
              </div>
              {dropdown === 'research' && (
                <ul className="dropdown-menu show">
                  <li><Link to='/methodology' onClick={closeMobileMenu}>Methodology</Link></li>
                  <li><Link to='/workpackages' onClick={closeMobileMenu}>Work Packages</Link></li>
                  <li><Link to='/publication' onClick={closeMobileMenu}>Publication</Link></li>
                </ul>
              )}
            </li>

            {/* Engagement Dropdown */}
            <li className='nav-item' onClick={() => toggleDropdown('engagement')}>
              <div className='nav-links'>
                Engagement
              </div>
              {dropdown === 'engagement' && (
                <ul className="dropdown-menu show">
                  <li><Link to='/events' onClick={closeMobileMenu}>Events</Link></li>
                  <li><Link to='/highlights' onClick={closeMobileMenu}>Highlights</Link></li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
