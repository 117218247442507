import React from 'react';

import Home from './components/pages/HomePage/Home.js';
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About  from './components/pages/about.js';
import Footer from './components/footer.js';
import Methodology  from './components/pages/Methodology.js';
import Highlights from './components/pages/Highlights.js';
import Publications from './components/pages/Publication.js';



import Events from './components/pages/UpcomingEvents.js';
import WorkPackages from './components/pages/WorkPackages.js';
import Team from './components/pages/teams.js';
import "@fontsource/league-spartan"; 




function App() {
  return (
    <div className="App">
      {/* <Logo /> */}
      <Router>
      <Navbar />
      <Routes> 
      <Route path='/' element={<Home />} /> 
      <Route path="/about" element={<About />} />
      <Route path="/methodology" element={<Methodology />} />
      <Route path="/highlights" element={<Highlights />} />
  
     
      <Route path="/events" element={<Events />} />
      <Route path="/workpackages" element={<WorkPackages />} />
      <Route path="/team" element={<Team />} />
      <Route path="/publication" element={<Publications />} />

      
      </Routes>
      <Footer />
      </Router>
  
    </div>
  );
}

export default App;