import React, { useState } from 'react';
import './workpackages.css';
import workpackage from '../../images/workpakages.png';
import startingImage from '../../images/hlly_range.png'; 
import HeadingPage from './headingpage.js';
import Midsection from './midsection';

const WorkPackages = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <HeadingPage 
        title="Work Packages" 
        description="Detailed Overview of Project Work Packages" 
        image={startingImage}
      />
      
      <div className='cards'>
        <div className='cards__container'>
          <div className='cards__wrapper'>

            <div className='cards__items'>
              <div className='cards__item'>
                <div className='cards__item__image'>
                  <img src={workpackage} alt="Threefold Scientific Approach" className="work-pack" />
                </div>
                <div className='cards__item__info'>
                  <div className='h1'>Overview</div>
                  <p className='paragraph1'>
                    <strong>Objective:</strong> This project addresses Sustainable Development Goals (SDGs) by prioritizing key targets, analyzing long-term impacts, and setting future sustainability goals. Through four work packages, the project combines scientific analysis with stakeholder engagement to ensure lasting sustainability.
                  </p>

                  <p className='paragraph1'>
                    {isExpanded ? (
                      <>
                        <div className='h1'>WP1: Prioritizing SDGs </div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> This work package is focused on identifying crucial targets and conditions for achieving Sustainable Development Goals (SDGs) in the allotted time involving three main tasks:
                        </p>
                        <ul className='list-disc'>
                          <li>Tracking and analyzing SDG interactions across different scales and identifying causal relationships.</li>
                          <li>Understanding the mechanisms behind SDG interactions through qualitative analysis and literature review.</li>
                          <li>Utilizing data from multiple sources, including the unified SDG database.</li>
                          <li>Developing causal SDG systems models to measure the influence of targets in SDG systems.</li>
                        </ul>

                        <div className='h1'>WP2: SDGs and Long-Term Sustainability </div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> This package aims to understand the impact of achieving (or not achieving) SDGs on long-term sustainability beyond 2030. Its tasks include:
                        </p>
                        <ul className='list-disc'>
                          <li>Identifying determinants of long-term sustainability and their thresholds.</li>
                          <li>Investigating the effects of underachieving SDGs on long-term sustainability using various scientific methods and scenario analysis.</li>
                          <li>Analyzing whether achieving SDGs is sufficient to ensure long-term sustainability, considering future scenarios.</li>
                        </ul>

                        <div className='h1'>WP3: Sustainability Targets for the Post-2030 Development Agenda</div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> This work package is dedicated to identifying next-generation targets for the post-2030 Sustainable Development Agenda.
                        </p>
                        <ul className='list-disc'>
                          <li>Identifying which current SDG targets should continue beyond 2030.</li>
                          <li>Proposing new sustainability targets beyond the existing SDGs based on critiques and gaps.</li>
                          <li>Co-creating and validating the next-generation sustainability targets through stakeholder consultations.</li>
                        </ul>

                        <div className='h1'>WP4: Stakeholder Partnership and Dissemination</div>
                        <p className='paragraph1'>
                          <strong>Objective:</strong> This is a cross-cutting work package focusing on developing stakeholder partnerships and disseminating knowledge.
                        </p>
                        <ul className='list-disc'>
                          <li>Developing stakeholder partnerships to facilitate project activities and knowledge co-creation.</li>
                          <li>Creating a web-based BeyondSDG platform for disseminating findings and developing stakeholder partnerships.</li>
                          <li>Publishing findings in various formats for scientific dissemination and policy impact, and hosting conferences and webinars for knowledge transfer.</li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <p className='paragraph1'><strong>WP1: Prioritizing SDGs</strong> - Identifies critical SDG targets and interactions, developing models to understand their influence.</p>
                        <p className='paragraph1'><strong>WP2: Long-Term Sustainability</strong> - Assesses how achieving or underachieving SDGs affects sustainability beyond 2030.</p>
                        <p className='paragraph1'><strong>WP3: Post-2030 Agenda</strong> - Defines next-generation sustainability targets for the post-2030 development agenda.</p>
                        <p className='paragraph1'><strong>WP4: Stakeholder Engagement</strong> - Builds partnerships and disseminates knowledge through a BeyondSDG platform, publications, and events.</p>
                      </>
                    )}
                  </p>
                  <button className='read-more-btn' onClick={toggleReadMore}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Midsection />
    </div>
  );
}

export default WorkPackages;
