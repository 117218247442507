import React from 'react';
import Imageheader from '../../images/Forest.jpg'; 
// import Imageheader from '../../images/17goals.jpg'; 
import './midsection.css';


function Midsection() {
  return (
    <div className="wave-section">
   <svg className="wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
   <path fill="#ffffff" fillOpacity="1" d="M0,128C480,32,960,224,1440,128L1440,0L0,0Z"></path>

</svg>
      <div className="wave-background">
        <img src={Imageheader} alt="Forest Background" className="background-image" />
      </div>
    </div>
  );
}

export default Midsection;
