import './footer.css';
import logofooter from '../images/irees-logo.png';
import Logo from '../images/logoFooter.jpg';
import ERC from '../images/ERC.png';
import ContactFormModal from './pages/contact';
import UNLogo from '../images/EU.png';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className='footer-container'>
      <div className='footer-content'>
        
        {/* Left Section - IREES & Groningen */}
        <div className='footer-section sponsor-container sponsor-left'>
          <Link to='https://www.rug.nl/research/irees/?lang=en' target='_blank'>
            <img src={logofooter} alt='IREES Logo' className='logo-image' />
          </Link>
          <Link to='https://www.rug.nl/' target='_blank'>
            <img src={Logo} alt='University of Groningen Logo' className='logo-image2' />
          </Link>
        </div>

        <div className='footer-section text-links'>
          <div className='footer-links'>
            <Link to='/'>HOME</Link>
            <Link to='/about'>ABOUT</Link>
            <button onClick={openModal}>CONTACT</button>
            <ContactFormModal isOpen={isModalOpen} onRequestClose={closeModal} />
          </div>
          <div className='footer-links'>
            <Link to='https://www.instagram.com/beyound.sdg/' target='_blank'>Instagram</Link>
            <Link to='/'>LinkedIn</Link>
            <Link to='https://twitter.com/BeyondSDG' target='_blank'>Twitter</Link>
          </div>
        </div>

        {/* Right Section - ERC & UN */}
        <div className='footer-section sponsor-container sponsor-right'>
          <Link to='https://erc.europa.eu/homepage' target='_blank'>
            <img src={ERC} alt='ERC Logo' className='logo-image3' />
          </Link>
          <Link to='https://www.un.org/' target='_blank'>
            <img src={UNLogo} alt='UN Logo' className='logo-image-un' />
          </Link>
        </div>

      </div>
    </div>
  );
}
export default Footer;
