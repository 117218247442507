import React, { useEffect, useState } from 'react';
import '../../../App.css';
import './Homepage_Title.css';
import { useNavigate } from 'react-router-dom';
import Image1 from '../../../images/image30.jpeg';
// import Image2 from '../images/image26.jpeg';
import Image3 from '../../../images/image27.jpeg';
// import Image4 from '../images/hlly_range.png';


function Homepage_Title() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [Image1, Image3]; // Array of images

  useEffect(() => {
    const heroContainer = document.querySelector('.hero-container');
    heroContainer.classList.add('animation-active');

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 20000); 

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/about');
  };

  const backgroundStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className='hero-container' style={backgroundStyle}>
      <h1>BEYONDSDG</h1>
      <p>"Transformation towards long-term sustainability beyond Sustainable Development Goals"</p>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-6 px-10 rounded-full mt-15 mb-10 lg:px-10"
        onClick={handleButtonClick}
      >
        LEARN MORE
      </button>
    </div>
  );
}

export default Homepage_Title;
