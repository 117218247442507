
import './publication.css';
import Image1 from '../../images/IMG_3502.jpg';
import HeadingPage from './headingpage.js';
import React, { useState } from 'react';

import Midsection from './midsection';



const publicationsData = [
    { id: 1, title: 'Trade-offs among human, animal, and environmental health hinder the uniform progress of global One Health', author: 'Ya Tian1, Junze Zhang, Zonghan Li, Bojie Fu, Min Chen, Hui Lin1', year: 2024,url:'https://www.cell.com/iscience/fulltext/S2589-0042%2824%2902582-3',publication:'iScience',volume:'27',issue:'12',page:''},
    {id: 2, title: 'Urban rooftops for food and energy in China', author: 'Yang, R., Xu, C., Zhang, H. et al', year: 2024,url:'https://www.nature.com/articles/s44284-024-00127-4#citeas',publication:'Nature Cities',volume:'1',issue:'',page:'742-250'},
    {id: 3, title: 'Revisiting the sustainability science research agenda', author: 'Sahle, M., Lahoti, S.A., Lee, SY. et al.', year: 2025,url:'https://link.springer.com/article/10.1007/s11625-024-01586-3#citeas',publication:'Sustain Sci ',volume:'',issue:'20',page:'1-19'},
    {id: 4, title: 'Three foci at the science-policy interface for systemic Sustainable Development Goal acceleration', author: 'Pradhan, P., Weitz, N., Daioglou, V. et al.', year: 2024,url:'https://www.nature.com/articles/s41467-024-52926-x',publication:'Nature Communications',volume:'15',issue:'',page:'4'},
    {id: 5, title: 'Beyond borders: Assessing global sustainability through interconnected systems', author: 'Zhang, Junze, et al.', year: 2024,url:'https://onlinelibrary.wiley.com/doi/10.1002/sd.3218',publication:'Sustainable Development',volume:'',issue:'',page:''},
    {id: 6, title: 'Decoupling of environmental impacts in the iron and steel sector is still needed beyond emissions', author: 'Chaohui Li, Prajal Pradhan', year: 2024,url:'https://onlinelibrary.wiley.com/doi/full/10.1002/sd.3204',publication:'Sustainable Development',volume:'',issue:'',page:''},
    {id: 7, title: 'Intranational synergies and trade-offs reveal common and differentiated priorities of sustainable development goals in China', author: 'Xing, Q., Wu, C., Chen, F. et al.', year: 2024,url:'https://www.nature.com/articles/s41467-024-46491-6#citeas',publication:'Sustainable Development',volume:'',issue:'',page:''},
    {id: 8, title: 'Urban agriculture matters for sustainable development', author: 'Pradhan, Prajal et al.', year: 2024,url:'https://www.cell.com/cell.../fulltext/S2949-7906%2824%2900349-5',publication:'Cell Reports Sustainability',volume:'1',issue:'9',page:'100217'},
    {id: 9, title: 'Policy relevance of IPCC reports for the Sustainable Development Goals and beyond', author: 'Pradhan, P., Joshi, S., Dahal, K., Hu, Y., Subedi, D. R., Putra, M. P. I. F., ... & van den Hurk, B.', year: 2025,url:'https://www.sciencedirect.com/science/article/pii/S2666916125000040?via%3Dihub',publication:'Resources, Environment and Sustainability',volume:'19',issue:'9',page:'1000192'},
   
];

const uniqueYears = [...new Set(publicationsData.map(publication => publication.year))].sort((a, b) => b - a);

const Publications = () => {
    const [selectedYear, setSelectedYear] = useState(null);

    // Filter and sort publications
    const filteredPublications = (selectedYear
        ? publicationsData.filter(publication => publication.year === selectedYear)
        : publicationsData
    ).sort((a, b) => b.year - a.year); // Sort by year in descending order

    return (
        <div>
          <HeadingPage 
  title="Publications & Resources" 
  description="Explore our publications and resources."
  staticImage={Image1}
/>
            <div className="publication_card">
                <h1 className='h1'>Publications</h1>
                
                <div className="year-buttons">
                    <button 
                        className={`year-button ${selectedYear === null ? 'active' : ''}`} 
                        onClick={() => setSelectedYear(null)}
                    >
                        All
                    </button>
                    {uniqueYears.map(year => (
                        <button 
                            key={year} 
                            className={`year-button ${selectedYear === year ? 'active' : ''}`} 
                            onClick={() => setSelectedYear(year)}
                        >
                            {year}
                        </button>
                    ))}
                </div>

                <div className="publication-list">
                    <ul className='publication_list'>
                        {filteredPublications.map(publication => (
                            <li className='publication_items' key={publication.id}>
                                <a href={publication.url} target="_blank" rel="noopener noreferrer">
                                {publication.author} <span style={{ fontStyle: 'italic' }}>({publication.year})</span> {publication.title} <span style={{ fontStyle: 'italic' }}>{publication.publication}</span> {publication.volume} {publication.issue} {publication.page} 
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <strong>Disclaimer:</strong> <em>The publications listed above serve as foundational and inspirational references...</em>
            </div>
            <Midsection />
        </div>
    );
};

export default Publications;