import React, { useState } from 'react';
import './landingPage.css';
import Image2 from '../../images/Logo_beyond.png';
import Image1 from '../../images/global1.png';


function Introduction() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const goalImages = Array.from({ length: 18 }, (_, i) =>
  require(`../../images/E_SDG_PRINT-${String(i + 1).padStart(2, '0')}.jpg`)
);


  return (
    <div>

    <div className="goals-grid">
        {/* First Row with 9 Images */}
        <div className="goals-row">
          {goalImages.slice(0, 9).map((image, index) => (
            <div key={index} className="goal-image">
              <img src={image} alt={`Goal ${index + 1}`} />
            </div>
          ))}
        </div>

        {/* Second Row with 8 Images */}
        <div className="goals-row">
          {goalImages.slice(9).map((image, index) => (
            <div key={index} className="goal-image">
              <img src={image} alt={`Goal ${index + 10}`} />
            </div>
          ))}
        </div>
      </div>
      
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <div className='cards__items'>
            <div className='cards__item'>
              <div className='cards__item__image'>
                <img src={Image1} alt='ERC Logo' className='Image_landing' />
              </div>
              <div className='cards__item__info'>
              <div className='h1'>Sustainable Goals</div>
              Sustainability is the practice of meeting current needs without compromising the ability of future generations to meet their own. It emphasizes a balance between economic growth, environmental care, and social well-being, often summarized as the "three pillars" of sustainability: economic, environmental, and social.
   
                <p className='paragraph1'>
                  {isExpanded ? (
                    <>
                      In 2015, the United Nations established 17 Sustainable Development Goals (SDGs) as part of the 2030 Agenda for Sustainable Development to create a comprehensive framework for promoting sustainable development globally. These goals aim to address various global challenges, including poverty, hunger, health, education, gender equality, clean water, clean energy, economic growth, innovation, reduced inequality, sustainable cities, responsible consumption, climate action, life below water and on land, peace, justice, and strong institutions, and partnerships to achieve these objectives. The SDGs emphasize the need for a balanced approach, recognizing that true sustainability requires addressing both human needs and environmental limits.
                    </>
                  ) : (
                    <>
                      In 2015, the United Nations established 17 Sustainable Development Goals (SDGs) as part of the 2030 Agenda for Sustainable Development to create a comprehensive framework for promoting sustainable development globally.
                    </>
                  )}
                </p>
                <button className='read-more-btn' onClick={toggleReadMore}>
                  {isExpanded ? 'Read Less' : 'Read More'}
                </button>
              </div>
            </div>
          </div>

          {/* Duplicate card for demonstration, content hidden initially */}
          <div className='cards__items'>
            <div className='cards__item'>
              <div className='cards__item__image'>
                <img src={Image2} alt='ERC Logo' className='Image_landing' />
              </div>
              <div className='cards__item__info'>
              <div className='h1'>BeyondSDG</div>
                BeyondSDG aims to accelerate progress towards the Sustainable Development Goals by deeply understanding and strategically acting on the complex interactions among the goals, ensuring transformative, sustainable changes beyond the 2030 Agenda.
                <p className='paragraph1'>
                  {isExpanded ? (
                    <>
                      BeyondSDG is a project funded by the European Research Council (ERC) starting grand. It aims to understand the necessary conditions for long-term sustainability, including achieving Sustainable Development Goals (SDGs). Countries are not on track to meet the 2030 Agenda for Sustainable Development, which comprises 17 SDGs and 169 targets to be achieved by 2030. Although SDGs aim to shift the world onto a sustainable and resilient path, countries are not yet able to make transformative changes for long-term sustainability that require building social prosperity and foundations within planetary boundaries. Failing to achieve SDGs will negatively affect billions of people and worsen environmental conditions and socio-economic problems. Therefore, BeyondSDG tests the following general hypothesis:
                    </>
                  ) : (
                    <>
                      BeyondSDG is a project funded by the European Research Council (ERC) starting grand. It aims to understand the necessary conditions for long-term sustainability, including achieving Sustainable Development Goals (SDGs).
                    </>
                  )}
                </p>
                <button className='read-more-btn' onClick={toggleReadMore}>
                  {isExpanded ? 'Read Less' : 'Read More'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>

  );
}

export default Introduction;
