import React from 'react';
import image3 from '../../images/Highlight_3.jpg';
import image1 from '../../images/Highlights_1.jpg';
import image2 from '../../images/Highlight_2.jpg';
import image4 from '../../images/Highlight_4.jpg';
import image5 from '../../images/Highlight_5.png';
import image6 from '../../images/Highlights_6.png';

import './highlight.css';
import startingImage from '../../images/Nepal.jpg'; 
import HeadingPage from './headingpage.js';
import Midsection from './midsection';

const Data = [
  {
    id: 1,
    title: 'Rescuing the Sustainable Development Goals from failing',
    description: 'Today, 18 September, the United Nations is holding a summit to review the state of the Sustainable Development Goals (SDGs). The SDGs’ impact is currently limited, experts wrote in Science last week. One of the authors is Prajal Pradhan from the University of Groningen, who aims to investigate what is needed to rescue the SDGs from failing. Five questions about his research plans',
    logo: image1,
    url:'https://www.rug.nl/sciencelinx/nieuws/2023/09/20230918-rescuingthesdgsfromfailing',
  },
  {
    id: 2,
    title: 'NANO of September 18, 2023: Sustainability goals - hardly achievable?',
    description: 'The globally agreed sustainability goals are intended to ensure socially, economically and ecologically sustainable development and thus a future worth living. But can we still achieve this? Overcome hunger in the world? Fight poverty? Ensure education for all? And all this by 2030?',
    logo: image5,
    url:'https://www.3sat.de/wissen/nano/230918-nachhaltigkeitsziele-kaum-erreichbar-sendung-nano-100.html',
  },
  {
    id: 3,
    title: 'Accelerating Sustainable Development: Insights from the Groningen Workshop',
    description: 'The 3-day workshop held in Groningen from April 22nd to 24th, 2024. The workshop was organized by dr. Prajal Pradhan (University Groningen) and the Rudolf Agricola School for Sustainable Development, was a crucial platform for accelerating the implementation of the Sustainable Development Goals (SDGs) in collaboration with the United Nations.',
    logo: image3,
    url:'https://www.rug.nl/rudolf-agricola-school/news-and-events/2024/sdg-workshop-groningen-2024',
  },
  {
    id: 4,
    title: 'Mega Study reveals the pros and cons of urban agriculture in reaching the SDGs',
    description: 'This research, using machine learning, systematically reviewed approximately 1,450 relevant publications from a pool of 76,000 records, offers new insights into the relationship between urban agriculture and the United Nations’ Sustainable Development Goals (SDGs).',
    logo: image2,
    url:'https://www.rug.nl/rudolf-agricola-school/news-and-events/2024/pradhal-urban-agriculture?lang=en',
  },
  {
    id: 5,
    title: 'How to accelerate Sustainable Development Goals? Researchers offer three foci at the science-policy interface',
    description: 'The article is based on a workshop held at the Rudolf Agricola School for Sustainable Development, University of Groningen, in the Netherlands, with participants from various countries representing researchers and policymakers',
    logo: image4,
    url:'https://www.rug.nl/rudolf-agricola-school/news-and-events/2024/prajal-pradhan-accelerating-sdgs?lang=en',
  },
  {
    id: 6,
    title: 'Linking climate change to SDGs for a more sustainable future',
    description: "With his fellow researchers, Pradhan published a study on the IPCC's fifth and sixth reports. The research revolves around the Sustainable Development Goals (SDGs) adopted by the United Nations in 2015. Using keywords associated with the SDG targets, the texts of the IPCC reports, totaling more than 150 chapters, were carefully analyzed. The researchers assessed if all 17 SDGs and their 169 targets are covered in the reports and how they are discussed whether in positive or negative terms",
    logo: image6,
    url:'https://www.rug.nl/wubbo-ockels-school/news/2025/linking-climate-change-to-sdgs-for-a-more-sustainable-future',
  },
];

const Highlightcards = ({ highlight }) => {
  return (
    <div className="EventsContainer">
      <img src={highlight.logo} alt={highlight.title} />
      <div className="event-details">
        <h3>{highlight.title}</h3>
        <p>{highlight.description}</p>
        <a href={highlight.url} target="_blank" rel="noopener noreferrer">
          <p className="read-more-btn">READ MORE</p>
        </a>
      </div>
    </div>
  );
};

const Highlights = () => {
  return (
    <div>
      <HeadingPage 
        title="Highlights" 
        description="" 
        image={startingImage}
      />
      <div className='main-card'>  
        <div className='highlight-container'>
          <div className='highlight-card'>
            {Data.sort((a, b) => b.id - a.id).map((highlight) => (
              <Highlightcards key={highlight.id} highlight={highlight} />
            ))}
          </div>
        </div>
      </div>
      <Midsection />
    </div>
  );
};

export default Highlights;
